import React from 'react';
import { graphql } from 'gatsby';
import {
  DontDo,
  IconItemList,
  List,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  Section,
} from '../../../../components';
import V5Notice from '../../v5-notice/V5Notice';
import pageHeroData from '../../../../data/pages/components.yml';

const IndexPage = ({ data }) => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Media Playback"
      designCode
      subnav="components">
      <PageHero
        heroData={pageHeroData}
        tierTwo="Icons"
        tierThree="Media Playback"
      />
      <V5Notice
        componentName="Icon"
        storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/general-icon--docs"
      />
      <PageNavigation links={['Icons', 'Usage', 'Platform']} />
      <Section title="Icons">
        <IconItemList data={data} />
      </Section>
      <Section title="Usage">
        <Paragraph>
          Media playback icons were designed to manipulate video and shouldn't
          be used where video is not.
        </Paragraph>
        <DontDo
          dontText="use media playback icons when video isn’t involved."
          doText="make adjusting playback for video easy."
          imgFilename="icons-mediaplayback"
        />
      </Section>
      <Section title="Platform">
        <PlatformTable
          platforms={{
            web: (
              <List>
                <li>Stick to the icons above.</li>
              </List>
            ),
            apple: (
              <List>
                <li>Stick to the icons above.</li>
              </List>
            ),
            android: (
              <List>
                <li>Stick to the icons above.</li>
              </List>
            ),
          }}
        />
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;

export const query = graphql`
  query iconMediaPlaybackDesign($title: String = "Media Playback") {
    ...iconDataDesign
  }
`;
